<template>
	<div class="page_box" v-loading="loading">
		 
		<div class="bom_box">
			 

		 



			<div class="bom_box_list" v-if="tab_show">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading">
					 
					<el-table-column prop="date" fixed label="日期" width="120">
						<template slot-scope="scope">
							<div slot="reference" class="" style="color: #2373C8;">
								{{ scope.row.gz_date }}
							</div>
						</template>
					</el-table-column>
				 
					<el-table-column :prop="item.id" :label="item.title" width="120"
						v-for="(item, index) in column_group[0].child" v-if="item.ac_show"></el-table-column>

					<el-table-column prop="mobile" :label="item.parent_title" width="120"
						v-for="(item, index) in column_group2" :key="index" v-if="(item.child.find(v => v.ac_show))">
						<!-- :prop="'fee_'+item.id+'.money'" -->
						<el-table-column :prop="`fee_${items.id}.money`" :label="items.title"
							v-for="(items, indexs) in item.child" width="120" v-if="items.ac_show"></el-table-column>

					</el-table-column>



					<el-table-column prop="actual_gz" label="应发工资" width></el-table-column>
					<el-table-column prop="payable_gz" label="实发工资" width></el-table-column>
 
				</el-table>
				<div class="pages">
					<page :pageson="count" @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
		 
	</div>
</template>
<script>
import page from "@/components/page/page.vue"; //分页  统一的
 
export default {
	components: {
		page,
		 
	},
	data() {
		return {
			loading: false, //加载总
			tab_show: false,
			page_title: "", //页面名称  来之路由
			check_list: [], //选中的
			//提交数据
			forms: {
				keyword: "",
				// depart_id: "",
				month: '',
			},
			bm_title:'',
			bm_list: [],//部门
			bm_list_ac: [],//部门选中
			props: {
				multiple: false,
				value: 'id',
				label: 'title'
			},
			list: [
			],
			pages: {
				page: 1,
				limit: 10,
			},
			count: 0,
			times: [], //时间范围

			//字段
			column_group: [{
				parent_title: "基本信息",

				child: [
					{
						id: "atten_day",
						title: "出勤",
						selected: false,
						ac_show: true,
					},
				],
			},
			],
			column_group2: [],
			pickerOptions: {
				disabledDate(time) {
					console.log("time", time);
					return time.getMonth() > new Date().getMonth();
				},
			},
		};
	},

	methods: {
		//接受条数
		currentPage(page) {
			console.log(page);
			this.pages.page = page;
			this.get_list();
		},
		// 接收条数
		row(row) {
			console.log(row);
			this.pages.limit = row;
			this.get_list();
		},
		 
		//统一的列表接口
		get_list() {
			this.loading = true;
			this.$api("empSalaryList", {
				 
				...this.pages,
				userId:this.$route.query.id
				// userId:432
			}, "post").then((res) => {
				this.loading = false;
				this.tab_show = true

				if (res.code == 200) {
					this.count = res.data.count;
					this.list = res.data.list;
				} else {
					alertErr(res.msg);
				}
			});
		},
		 
	 
	  
		 
		get_type() {
			this.loading = true;
			this.$api("feeProjectList", {
				// mobile:this.mobile
				...this.forms,
				...this.pages,
			}, "post").then((res) => {
				this.loading = false;
				if (res.code == 200) {
					console.log('配置列表', res)
					let obj = {};
					let list = res.data
					list.forEach((item, index) => {
						item.ac_show = true
						// this.$set(item,'ac_show',true)
						let { parent_title } = item;
						if (!obj[parent_title]) {
							obj[parent_title] = {
								parent_title,
								child: [],//全部的
							}
						}
						obj[parent_title].child.push(item);
					});
					let data = Object.values(obj);
					console.log('结果', data)
					this.column_group2 = data


				} else {
					alertErr(res.msg);
				}
			});
		},
		 
	},
	computed: {},
	watch: {},
	created() {
	 
		this.get_list()
		this.get_type()

	},
	mounted() { },
};
</script>

<style scoped lang="less">
 

.page_box {
	height: 100%;

	// background-color: red;
	 

	.bom_box {
		background: #ffffff;

		.bom_box_list {
			// width: 1640px;
			// height: 670px;
			padding: 20px 20px 32px 20px;

			.pages {
				padding-top: 30px;
			}
		}
	}
}
</style>